<template>
  <div class="expandable" :class="{ 'expandable--non-expandable': !expandable, 'expandable--sticky': sticky }">
    <div class="expandable__limit" ref="container" :style="{ maxHeight: expandable ? maxHeight + 'px' : 'none' }">
      <div class="expandable__content" ref="content"><slot /></div>
    </div>
    <LinkButton class="expandable__btn" v-if="expandable && contentHeight > maxHeight" @click.stop="toggle">
      {{ !expanded ? expandText : shrinkText }}
      <IconChevronUp v-if="expanded" class="icon" />
      <IconChevronDown v-else class="icon" />
    </LinkButton>
  </div>
</template>

<script setup>
import LinkButton from '@/components/common/LinkButton'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-vue'

const props = defineProps({
  maxHeight: {
    type: Number,
    default: 120
  },
  expandText: {
    type: String,
    default: 'Развернуть'
  },
  shrinkText: {
    type: String,
    default: 'Свернуть'
  },
  animationDuration: {
    type: Number,
    default: 400
  },
  expandable: {
    type: Boolean,
    default: true
  },
  sticky: Boolean
})

const container = ref()
const content = ref()
const { height: contentHeight } = useElementSize(content)
const expanded = ref(false)

const toggle = () => {
  expanded.value = !expanded.value
  applyHeight()
}
const applyHeight = () => {
  if (!props.expandable) return

  const options = {
    duration: props.animationDuration,
    fill: 'both',
    easing: 'ease'
  }

  if (expanded.value) {
    container.value.animate(
      [
        { maxHeight: contentHeight.value + 'px' }
      ],
      options
    )
  } else {
    container.value.animate(
      [
        { maxHeight: props.maxHeight + 'px' }
      ],
      options
    )
  }
}
watch(contentHeight, applyHeight)
</script>

<style scoped lang="scss">
.expandable {
  &__limit {
    max-height: 120px;
    overflow: hidden;
  }
  &__content {
    margin-top: 0;
  }
  &__btn {
    margin-top: 15px;
    color: var(--color-primary) !important;
  }
  &--non-expandable {
    .expandable__limit {
      max-height: none;
    }
  }
  &--sticky {
    .expandable__btn {
      position: sticky;
      bottom: 0;
      padding: 15px 0;
      margin-bottom: -15px;
      margin-top: 0;
      background: linear-gradient(to bottom, transparent 0%, var(--color-bg) 50%);
      width: 100%;
    }
  }
}
</style>
